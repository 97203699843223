<template>
  <v-footer id="home-footer" color="white" dark min-height="72">
    <v-container>
      <v-row>
        <v-col cols="12" md="12">
          <v-img
            :src="require('@/assets/mullshad.png')"
            contain
            max-width="200"
            class="test"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center" cols="12">
          <a href="https://y-squared.com/">
            <p class="texty">
              Designed & Created by Y-Squared.
            </p>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "HomeFooter",

  data: () => ({
    social: ["Facebook", "Twitter", "Instagram", "Linkedin"]
  })
};
</script>

<style scoped>
.texty {
  color: black;
  margin: auto;
}

.test {
  margin: auto;
}
</style>
